import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import logo from '../assets/GT-Logo.jpg'; // Update the path to your actual logo

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ width: 250 }} onClick={handleDrawerToggle}>
      <List>
        {[
          { text: 'Home', link: '/' },
          { text: 'About Us', link: '/about' },
          { text: 'Solutions', link: '/solutions' },
          { text: 'Portfolio', link: '/portfolio' },
          { text: 'Get Started', link: '/contact' },
        ].map((item) => (
          <ListItem button key={item.text} component={Link} to={item.link}>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Logo */}
        <Box
          component={Link}
          to="/"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <img src={logo} alt="Genesis Tech Logo" style={{ height: 60 }} />
        </Box>

        {/* Desktop Menu */}
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Button component={Link} to="/" color="inherit" sx={{ mx: 1 }}>
            Home
          </Button>
          <Button component={Link} to="/about" color="inherit" sx={{ mx: 1 }}>
            About Us
          </Button>
          <Button
            component={Link}
            to="/solutions"
            color="inherit"
            sx={{ mx: 1 }}
          >
            Solutions
          </Button>
          <Button
            component={Link}
            to="/portfolio"
            color="inherit"
            sx={{ mx: 1 }}
          >
            Portfolio
          </Button>
          <Button
            variant="contained"
            color="success"
            component={Link}
            to="/contact"
          >
            Get Started
          </Button>
        </Box>

        {/* Mobile Menu */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: 'block', md: 'none' } }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
          {drawer}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
